@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap");
*,
body,
html {
  font-family: "Syne" !important;

  }


.flowbite-accordion {
  border-bottom: none !important;
}

.accordion-title{
  border-bottom: 1px solid white !important;

}

.inner-cards {
  box-shadow: 8px 8px 0px rgba(0, 0, 0, 1.2);
}
.faq-cards {
  box-shadow: 6px 6px 0px rgba(0, 0, 0, 1.2);
 
}
.faqs-card{
  border-width: 3px !important;
}
.placeholder-white::placeholder {
  color: white;
}
.Toastify__toast-container {
  z-index: 99999 !important;
}

.img{
  height: 100% !important;
}

.curveLineSection{
  position: relative;
  display: inline-block;
  white-space: nowrap;
 

  }
  
  .curveLineSection::after{
      content: "";
      position: absolute;
      bottom: -1.5rem;
      left: 0;
      width:100%;
      height: 20px;
      background-image: url('./assets/third-section/LineSection3.svg');
      background-repeat: no-repeat;
   

  }

  .card{
    width: 400px !important;
    height: 400px !important;
  }
  
  @media only screen and (min-width:768px )  {
    .card{
      width: 300px !important;
      height: 300px !important;
    }

  }

  .section1-bg{
    background-image: url(./assets/sectionBackground/section1-Bg.svg);
    background-size: cover;
    z-index: 5;
    margin-bottom: -100px;
    padding: 0rem 0rem 6rem 0rem; 
    position: relative;
    background-position: bottom center;
    background-repeat: no-repeat;
    box-sizing: border-box;
    width: 100%;
    top: 0;
   
  }

  .section1-main{
    display: flex;
    text-align: left;
    color: white;
    gap: 2rem;
    
    padding-bottom: 4.25rem;
   
  }
  .section1-shapes{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;
    padding: 0rem 8rem;

  }
  .section1-banner{
    display: none;
  }
  .section1-shape-1{
    display: block;
  }
  .section1-shape-2{
    display: block;

  }
  .section1-col-2{
    display: flex;
    
    flex-direction: column;
    width: 40%;
  }
  .section1-logo{
    display: block;
  }
  .section1-heading{
    font-size: 1.25rem; 
    line-height: 1.75rem;
    font-weight: 700;
  }
  .section1-subheading{
    font-size: 40px !important;
    font-weight: 700;
    width: 100%;
    padding: 1.25rem 0rem;
  }
  .section1-para{
    font-size: 20px;
    margin-top: 1.5rem;
  }
  .section1-siteslogo{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    padding: 2.5rem 0rem 2.5rem 0rem;

  }
 .google-logo{
  width:auto;
 }
 .app-store{
  width: auto;
 }
 .section1-hero{
  display: block;
 }


  

  
  
   @media only screen and (min-width:768px) {
    .section1-bg{
      z-index: 10;
    }

    .section1-hero{
    width: 80%;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .section1-bg {
      z-index: 5;
      position: relative;
      padding: 3rem 0rem 6rem 0rem;

    }
    .section1-main{
      text-align: center;
      padding: 0rem 1rem;
    }
    .section1-shapes{
      display: none;
    }
    .section1-shape-1{
      display: none;
    }
    .section1-shape-2{
      display: none;
    }
    .section1-col-2{
      width: 100%;
      align-items: center;
    }
    .section1-logo{
      display: none;
    }
    .section1-heading{
      margin-top: 1rem;
      font-size: 1rem;
      line-height: 0.8rem;
    }
    .section1-banner{
      display: block;
      overflow-x: hidden;
    
    }
    .section1-subHeading{
      font-size: 30px ; 
      line-height: 3.5rem;
      font-weight: 700;

    }
    .section1-para{
      font-size: 18px;
    }
    .google-logo{
      width:50%;
     }
    .app-store{
      width:50%;
    }
    .section1-hero{
      display: none;
    }
    
  }

 
  
  .section2-bg{
    background-color: #587CFA;
    padding: 6rem 0rem 8rem 0rem; 
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    
  }
  @media only screen and (max-width: 768px) {
    .section2-bg {
      padding: 7rem 0rem; 
    }
  }
  .section3-bg{
    background-image: url(./assets/sectionBackground/section3-Bg.svg);
    background-size: cover;
    margin-top: -250px;
    padding: 15rem 0rem 15rem 0rem;  
    z-index: 1;
    position: relative;
    box-sizing: border-box;
   
  }
  @media only screen and (max-width: 768px) {
    .section3-bg {
      background-image: url(./assets/multipleBackground/03_Section.svg);
      background-size: cover;
      margin-top: -132px;
      padding: 12rem 0rem 24rem 0rem; 
    }
  }

  .section4-bg{
    background-image: url(./assets/sectionBackground/section4-Bg.svg);
    background-size: cover;
    margin-top: -400px;
    background-repeat: no-repeat;
    padding: 20rem 0rem 20rem 0rem ; 
    z-index: 2;
    position: relative;
  }
  @media only screen and (max-width: 768px) {
    .section4-bg {
      background-image: url(./assets/sectionBackground/section4-MobBg.svg);
      background-size: cover;
      margin-top: -200x;
      padding: 6rem 0rem 22rem 0rem ; 
    }
  }
 
  .section5-bg{
    background-color: #FF89BB;
    margin-top: -310px;
    padding: 20rem 0rem 10rem 0rem ; 
    z-index: 0;
    position: relative;
  }
  @media only screen and (max-width: 768px) {
    .section5-bg {
      padding: 20rem 0rem 5rem 0rem ; 
    }
  }
  .footer{
    background-image: url(./assets/sectionBackground/section5-Bg.svg);
    background-size: cover;
    margin-top: -120px;
    background-repeat: no-repeat;
    padding: 10rem 0rem 0rem 0rem ; 
    z-index: 2;
    position: relative;
  }
  @media only screen and (max-width: 768px) {
    .footer {
      background-image: url(./assets/sectionBackground/FooterMob.svg);
      background-size: cover;
      margin-top: -80px;
      padding: 5rem 0rem 0rem 0rem ; 
    }
  }

  @media screen and (min-width:2000px) and (max-width:3839px) {

    .section1-bg{
      margin-bottom: -150px !important;
    }
    .section2-bg{
      margin-top: -150px;
      
      padding: 14rem 0rem 14rem 0rem !important; 
    }
    .section3-bg {
      margin-top: -370px;
      padding-top: 400px;
      background-image: url(./assets/multipleBackground/yellow2k.png);
      background-size: 100% 100%;
    }
   .section4-bg{
    background-image: url(./assets/multipleBackground/green2k.png);
    background-size: 100% 100%;


   }
   .girlPic{  
    margin-bottom: -70px;
   }
  }
  @media screen and (min-width:3840px) {
    .section2-bg{
      margin-top: -200px;
      padding: 20rem 0rem 20rem 0rem !important; 
    }
    .section3-bg {
      margin-top: -500px;
      padding-top: 400px;
      background-image: url(./assets/multipleBackground/yellow4k.png);
    background-size: cover;
  background-repeat: no-repeat;  
}

.section4-bg{
  background-image: url(./assets/multipleBackground/green4k.png);
  background-size: 100% 100%;

}
.footer {
  margin-top: -200px;
}

  }