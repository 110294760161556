
    @media screen and (min-width:320px) and (max-width:375px) {
    .section-4{
        margin-top: -200px;
        }
    }
    @media screen and (min-width:375px)  {
        .section-4{
        margin-top: -370px;
        }
    }
    @media screen and (min-width:425px)   {
        .section-4{
        margin-top: -550px;
    }
    @media screen and (min-width:768px)   {
        .section-4{
        margin-top: 0px;
    }
    }
}